import React from "react";

interface RangeProps {
  min: string;
  max: string;
  onChange: (type: "min" | "max", value: string) => void;
  minValue: string;
  maxValue: string;
  minAllowed?: number;
  maxAllowed?: number;
  allowDecimals?: boolean;
}

const formatNumber = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Range: React.FC<RangeProps> = ({
  min,
  max,
  onChange,
  minValue,
  maxValue,
  minAllowed = 0,
  maxAllowed = Infinity,
  allowDecimals = false,
}) => {
    const handleInputChange = (type: "min" | "max", value: string) => {
        let formattedValue = allowDecimals
          ? value.replace(/[^0-9.]/g, "") // Remove non-numeric characters except decimal points
          : value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    
        // Convert to number for comparison
        const numericValue = parseFloat(formattedValue);
    
        // Validate against min and max allowed values
        if (!isNaN(numericValue)) {
          if (numericValue < minAllowed) {
            formattedValue = minAllowed.toString();
          } else if (numericValue > maxAllowed) {
            formattedValue = maxAllowed.toString();
          }
        }
    
        onChange(type, formattedValue);
      };

  return (
    <div>
      <div className="flex gap-2 items-center">
        <input
          onChange={(e) => handleInputChange("min", e.target.value)}
          className="p-3.5 h-11 flex items-center border-2 border-gray-300 rounded text-sm font-bold font-sans text-gray-700 w-1/2 no-arrows"
          placeholder="Min"
          type="text"
          id={min}
          name={min}
          value={formatNumber(minValue) || ""}
        />
        <span className="font-normal text-sm">to</span>
        <input
          onChange={(e) => handleInputChange("max", e.target.value)}
          className="p-3.5 h-11 flex items-center border-2 border-gray-300 rounded text-sm font-bold font-sans text-gray-700 w-1/2 no-arrows"
          placeholder="Max"
          type="text"
          id={max}
          name={max}
          value={formatNumber(maxValue) || ""}
        />
      </div>
    </div>
  );
};

export default Range;