import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchTypeaheadOptions } from "./helpers";

interface SearchFilterOption {
  label: string;
  value: string;
}
interface SearchFilter {
  name: string;
  options: SearchFilterOption[];
  type: string;
  param: string;
}

interface SearchFilters {
  [key: string]: SearchFilter;
}

const SEARCH_FILTERS: SearchFilters = {
  AVAILABILITY: {
    name: "Availability",
    options: [
      { label: "For Sale", value: "sale" },
      { label: "For Lease", value: "lease" },
    ],
    type: "checkbox",
    param: "availability",
  },
  TYPE: {
    name: "Type",
    options: [
      { label: "Retail", value: "retail" },
      { label: "Industrial", value: "industrial" },
      { label: "Office", value: "office" },
      { label: "Land", value: "land" },
      { label: "Multifamily", value: "multifamily" },
      { label: "Hospitality", value: "hospitality" },
      { label: "Special Purpose", value: "special_purpose" },
    ],
    type: "checkbox",
    param: "type",
  },
  PRICE: {
    name: "Price",
    options: [],
    type: "range",
    param: "price",
  },
  BUILDING_SIZE: {
    name: "Building Size",
    options: [],
    type: "range",
    param: "size",
  },
  LOT_SIZE: {
    name: "Lot Size",
    options: [],
    type: "range",
    param: "lot_size",
  },
  PORTFOLIO: {
    name: "Portfolio",
    options: [
      { label: "Seritage Growth Properties", value: "4" },
      { label: "Walgreens", value: "6" },
      { label: "Walmart", value: "7" },
    ],
    type: "checkbox",
    param: "portfolio",
  },
  TENANCY: {
    name: "Tenancy",
    options: [
      { label: "Single-Tenant", value: "single-tenant" },
      { label: "Multi-Tenant", value: "multi-tenant" },
    ],
    type: "checkbox",
    param: "tenancy",
  },
  TENANT: {
    name: "Tenant",
    options: [],
    type: "typeahead",
    param: "tenant",
  },
  OFFICE: {
    name: "Office",
    options: [],
    type: "typeahead",
    param: "office",
  },
  BROKER: {
    name: "Broker",
    options: [],
    type: "typeahead",
    param: "broker",
  },
  OWNERSHIP_TYPE: {
    name: "Ownership Type",
    options: [
      {
        label: "Fee Simple (Land & Building)",
        value: "fee-simple-land-building",
      },
      { label: "Ground Lease (Land Only)", value: "ground-lease-land-only" },
      { label: "Leasehold (Lease Only)", value: "leasehold-lease-only" },
      { label: "Other", value: "other" },
    ],
    type: "checkbox",
    param: "lease_type",
  },
  CAP_RATE: {
    name: "Cap Rate",
    options: [],
    type: "range",
    param: "cap_rate",
  },
};

interface PopularCategory {
  label: string;
  url: string;
}

interface SearchState {
  filters: {
    type?: string[];
    price_min?: number;
    price_max?: number;
    size_min?: number;
    size_max?: number;
    lot_size_min?: number;
    lot_size_max?: number;
    cap_rate_min?: number;
    cap_rate_max?: number;
    availability?: string[];
    portfolio?: string[];
    lease_type?: string[];
    tenancy?: string[];
    tenant?: string;
    office?: string;
    broker?: string;
  };
  searchTerms: string;
  currentTab: string;
}

const withSearch = <P extends object>(
  WrappedComponent: React.ComponentType<P & SearchProps>,
  rootUrl: string = ""
) => {
  return function WithSearchComponent(props: P) {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchState, setSearchState] = React.useState<SearchState>({
      filters: {
        availability: ["sale", "lease"],
        type: [
          "retail",
          "industrial",
          "office",
          "land",
          "multifamily",
          "hospitality",
          "special_purpose",
        ],
        portfolio: [],
        tenancy: ["single-tenant", "multi-tenant"],
        lease_type: [
          "fee-simple-land-building",
          "ground-lease-land-only",
          "leasehold-lease-only",
          "other",
        ],
      },
      searchTerms: "",
      currentTab: "SALE_OR_LEASE",
    });
    const [filterOptions, setFilterOptions] =
      React.useState<SearchFilters>(SEARCH_FILTERS);
    const [popularCategories, setPopularCategories] = React.useState<
      PopularCategory[]
    >([
      { label: "Anchor Space", url: "https://srsre.com/properties/sale-or-lease?size_min=10000" },
      { label: "Land", url: "https://srsre.com/properties/sale-or-lease/land" },
      { label: "Shop Space", url: "https://srsre.com/properties/sale-or-lease/retail?size_max=5000" },
      { label: "Industrial", url: "https://srsre.com/properties/sale-or-lease/industrial" },
      { label: "Pad Sites", url: "https://srsre.com/properties/sale-or-lease/retail?size_req=false&lot_size_min=0&lot_size_max=3&lot_size_req=false" },
    ]);

    React.useEffect(() => {
      // Fetch filter options from an API or other data source
      const fetchFilterOptions = async () => {
        try {
          const response = await fetchTypeaheadOptions(); // Replace with your API endpoint
          // set the filter options for tenant, office, and broker
          const tenantOptions = response.tenants || [];
          const officeOptions = response.offices || [];
          const brokerOptions = response.brokers || [];

          setFilterOptions((prevFilters) => ({
            ...prevFilters,
            TENANT: {
              ...prevFilters.TENANT,
              options: tenantOptions,
            },
            OFFICE: {
              ...prevFilters.OFFICE,
              options: officeOptions,
            },
            BROKER: {
              ...prevFilters.BROKER,
              options: brokerOptions,
            },
          }));
        } catch (error) {
          console.error("Error fetching filter options:", error);
        }
      };
      fetchFilterOptions();
      
    }, []);

    // Parse URL query params on mount
    React.useEffect(() => {
      const params = new URLSearchParams(location.search);
      const newState = parseQueryParams(params);
      setSearchState((prev) => ({ ...prev, ...newState }));
    }, [location.search]);

    // update search filters
    const updateSearchFilters = (
      newFilters: Partial<SearchState["filters"]>
    ) => {
      const updatedState = {
        ...searchState,
        filters: {
          ...searchState.filters,
          ...newFilters,
        },
      };
      setSearchState(updatedState);
    };

    // update current tab
    const updateCurrentTab = (tab: string) => {
      setSearchState((prevState) => ({
        ...prevState,
        currentTab: tab,
      }));
    };

    // update search terms
    const updateSearchTerms = (searchTerms: string) => {
      setSearchState((prevState) => ({
        ...prevState,
        searchTerms: searchTerms,
      }));
    };

    // handle navigation
    const navigateToURL = () => {
      const state = searchState;
      let currentFilters = { ...state.filters };
      const params = new URLSearchParams();

      // Generate the path based on current tab
      let basePath = "";
      if (state.currentTab === "INVESTMENT_SALE") {
        basePath = "/investment-sale";
      } else {
        basePath = "/sale-or-lease";
        if (currentFilters.availability) {
          if (currentFilters.availability.length === 1) {
            basePath = `/${currentFilters.availability[0]}`;
          }
        }
      }

      // cleanup unecessary filters
      for (const key in currentFilters) {
        const filterName = key as keyof SearchState["filters"];

        // remove empty filters
        if (
          currentFilters[filterName] === undefined ||
          currentFilters[filterName] === null
        ) {
          delete currentFilters[filterName];
        }

        // if the filter is an array, check if it includes all the values in the filter options and remove it if it does
        if (Array.isArray(currentFilters[filterName])) {
          const filter = Object.values(SEARCH_FILTERS).find(
            (filter) => filter.param === filterName
          );
          if (
            filter &&
            (filter.options.length ===
              (currentFilters[filterName] as string[]).length ||
              (currentFilters[filterName] as string[]).length === 0)
          ) {
            delete currentFilters[filterName];
          }
        }
      }

      // remove availability from filters
      delete currentFilters.availability;

      // update basePath with type if it exists or is investment sale
      if (
        (state.currentTab === "INVESTMENT_SALE" && currentFilters.tenant) ||
        (currentFilters.type && currentFilters.type.length > 0)
      ) {
        // if type is in the filters and it is not all, add it to the path separated by hyphens
        if (
          currentFilters.type &&
          (currentFilters.type as string[]).length <
            SEARCH_FILTERS.TYPE.options.length
        ) {
          basePath += `/${(currentFilters.type as string[]).join("-")}`;
        } else if (state.currentTab === "INVESTMENT_SALE") {
          basePath += "/all-types";
        }

        // remove type from filters
        delete currentFilters.type;
      }

      // if currentFilters.tenant exists, add it to the path
      if (currentFilters.tenant) {
        // tenant is only used for investment sale, so we need to check if it exists
        if (state.currentTab === "INVESTMENT_SALE") {
          basePath += `/all-states/all-cities/all-neighborhoods/${currentFilters.tenant}`;
        }
        // remove tenant from filters
        delete currentFilters.tenant;
      }

      // add filters to params
      for (const key in currentFilters) {
        const filterName = key as keyof SearchState["filters"];
        if (currentFilters[filterName]) {
          let paramValue = "";
          if (Array.isArray(currentFilters[filterName])) {
            paramValue = (currentFilters[filterName] as string[]).join(",");
          } else {
            paramValue = String(currentFilters[filterName]);
          }

          params.append(filterName, paramValue);
        }
      }

      if (state.searchTerms) {
        params.append("s", state.searchTerms);
      }

      console.log(`Navigating to ${rootUrl}${basePath}?${params.toString()}`);
      // handle navigation
      if (rootUrl === '') {
        navigate({
          pathname: basePath,
          search: params.toString(),
        });
      } else {
        // navigate to the new URL
        window.location.href = `${rootUrl}${basePath}?${params.toString()}`;
      }
    };

    const parseQueryParams = (
      params: URLSearchParams
    ): Partial<SearchState> => {
      const state: Partial<SearchState> = { filters: {} };
      // parse query params and update state
      return state;
    };

    const searchProps = {
      searchState,
      updateSearchFilters: updateSearchFilters,
      updateCurrentTab,
      updateSearchTerms,
      navigateToURL,
      filterOptions,
      popularCategories,
    };

    return <WrappedComponent {...props} {...searchProps} />;
  };
};

interface SearchProps {
  searchState: SearchState;
  updateSearchFilters: (filters: Partial<SearchState["filters"]>) => void;
  updateCurrentTab: (tab: string) => void;
  updateSearchTerms: (searchTerms: string) => void;
  navigateToURL: () => void;
  filterOptions: SearchFilters;
  popularCategories: PopularCategory[];
}

export type {
  SearchState,
  SearchProps,
  SearchFilter,
  SearchFilterOption,
  SearchFilters,
};
export { SEARCH_FILTERS };
export default withSearch;
