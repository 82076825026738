import React, { useState, useEffect } from "react";
import Popover from "./Popover";
import withSearch, { SearchProps, SearchFilter } from "./SearchHOC";
import Typeahead from "./Typeahaead";
import Range from "./Range";
import Checkbox from "./Checkbox";
import { formatRangeDisplayValues } from "./helpers";

interface Tab {
  name: string;
  value: string;
}

interface Tabs {
  [key: string]: Tab;
}

interface SelectedSearchFilter {
  name: string;
  value: string[] | string | number | boolean;
}

const NAVIGATION_TABS: Tabs = {
  SALE_OR_LEASE: {
    name: "For Sale or Lease",
    value: "SALE_OR_LEASE",
  },
  INVESTMENT_SALE: {
    name: "Investment Sale",
    value: "INVESTMENT_SALE",
  },
};

const SearchWidget: React.FC<SearchProps> = ({
  updateSearchFilters,
  updateCurrentTab,
  updateSearchTerms,
  navigateToURL,
  filterOptions,
  popularCategories,
}) => {
  const [currentTab, setCurrentTab] = React.useState<Tab>(
    NAVIGATION_TABS.SALE_OR_LEASE
  );

  const DISPLAY_FILTERS: { [key: string]: SearchFilter[] } = {
    SALE_OR_LEASE: [
      filterOptions.AVAILABILITY,
      filterOptions.TYPE,
      filterOptions.PRICE,
      filterOptions.BUILDING_SIZE,
      filterOptions.LOT_SIZE,
      filterOptions.PORTFOLIO,
      filterOptions.BROKER,
      filterOptions.OFFICE,
    ],
    INVESTMENT_SALE: [
      filterOptions.TYPE,
      filterOptions.PRICE,
      filterOptions.BUILDING_SIZE,
      filterOptions.LOT_SIZE,
      filterOptions.TENANCY,
      filterOptions.TENANT,
      filterOptions.OWNERSHIP_TYPE,
      filterOptions.CAP_RATE,
      filterOptions.BROKER,
      filterOptions.OFFICE,
    ],
  };

  const [searchFilters, setSearchFilters] = React.useState<
    SelectedSearchFilter[]
  >([
    // default filters is all
    { name: "availability", value: ["sale", "lease"] },
    {
      name: "type",
      value: [
        "retail",
        "industrial",
        "office",
        "land",
        "multifamily",
        "hospitality",
        "special_purpose",
      ],
    },
    { name: "portfolio", value: [] },
    { name: "tenancy", value: ["single-tenant", "multi-tenant"] },
    {
      name: "lease_type",
      value: [
        "fee-simple-land-building",
        "ground-lease-land-only",
        "leasehold-lease-only",
        "other",
      ],
    },
  ]);

  const onUpdateSelectedFilters = (
    filter: string,
    option: string | number | boolean,
    type: string
  ) => {
    let currentFilters = searchFilters;
    const filterIndex = currentFilters.findIndex(
      (searchFilter) => searchFilter.name === filter
    );

    if (type === "checkbox") {
      if (filterIndex === -1) {
        currentFilters = [
          ...currentFilters,
          { name: filter, value: [option.toString()] },
        ];
      } else {
        const filterValues = currentFilters[filterIndex].value as string[];
        const optionIndex = filterValues.findIndex((value) => value === option);
        if (optionIndex === -1) {
          filterValues.push(option.toString());
        } else {
          filterValues.splice(optionIndex, 1);
        }
        currentFilters[filterIndex].value = filterValues;
      }
      // update others
    } else {
      if (filterIndex === -1) {
        currentFilters = [...currentFilters, { name: filter, value: option }];
      } else {
        currentFilters[filterIndex].value = option;
      }
    }

    setSearchFilters([...currentFilters]);

    // if currentFilters includes all values, then remove from

    const transformedFilters = currentFilters.reduce((acc, filter) => {
      if (Array.isArray(filter.value)) {
        acc[filter.name] = filter.value;
      } else {
        acc[filter.name] = filter.value;
      }
      return acc;
    }, {} as Partial<{ [key: string]: string | number | boolean | string[] }>);
    updateSearchFilters(transformedFilters);
  };

  const handleTabChange = (tab: string) => {
    setCurrentTab(NAVIGATION_TABS[tab]);
    updateCurrentTab(tab);
  };

  return (
    <div className="SearchWidget">
        <div className="w-full">
          <div className="sm:ml-6 flex sm:space-x-4 md:m-0">
            {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
            {Object.keys(NAVIGATION_TABS).map((tab) => (
              <button
                key={tab}
                onClick={() => handleTabChange(tab)}
                className={`inline-flex items-center px-8 pt-2 pb-1 m-0 text-[20px] sm:text-[24px] leading-snug sm:leading-normal font-extrabold italic rounded-none ${
                  currentTab.value === tab
                    ? "border-indigo-500 bg-white text-[#0C273D]"
                    : "border-transparent bg-[#35647e] text-white"
                }`}
              >
                <span
                  className={`${
                    currentTab.value === tab
                      ? "border-b-[3px] py-[10px] border-[#7FB2BA]"
                      : "py-[10px]"
                  }`}
                >
                  {NAVIGATION_TABS[tab].name}
                </span>
              </button>
            ))}
          </div>
          {/* Create Search Box Container component for this thing... */}
          <div className="w-full bg-white text-gray-700 font-bold text-left px-8 md:px-0 md:py-10 flex flex-col md:flex-row">
            <div className="SearchWidget-searchBox md:px-8 py-10 md:py-3">
              <div className="flex flex-col sm:flex-row ">
                <input
                  type="text"
                  className="flex-1 px-3 text-lg placeholder-gray-400 font-[400] font-sans border border-gray-300 sm:mr-4 rounded-none focus:shadow-outline py-3 mb-4 sm:mb-0"
                  placeholder="Enter an address, city, state or ZIP code"
                  onChange={(e) => updateSearchTerms(e.target.value)}
                ></input>
                <button
                  className="bg-[#7FB2BA] px-4 py-2 italic text-white text-[24px] font-extrabold rounded-none"
                  onClick={() => navigateToURL()}
                >
                  Find properties
                </button>
              </div>
              <div className="mt-6">
                <h2 className="text-[#7fb2ba] uppercase font-normal tracking-wide mb-3 text-xl font-sans">
                  Filters
                </h2>
                <SearchFiltersComponent
                  displayFilters={DISPLAY_FILTERS[currentTab.value]}
                  selectedFilters={searchFilters}
                  onUpdateSelectedFilters={onUpdateSelectedFilters}
                />
              </div>
            </div>
            {popularCategories && popularCategories.length > 0 && (
              <div className="SearchWidget-categories md:px-8 py-10 md:py-2 text-xl border-t-2 border-gray-200 md:border-l-2 md:border-t-0">
                <h2 className="text-[#7fb2ba] uppercase font-normal tracking-wide font-sans text-xl mb-4">
                  Popular Categories
                </h2>
                <ul className="list-none p-0 m-0">
                  {popularCategories.map((category, index) => (
                    <li key={`category_${index}`} className="leading-normal ml-0">
                      <a href={category.url} className="font-extrabold italic no-underline hover:no-underline text-[#0C273D]">
                        {category.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
    </div>
  );
};

interface SearchFiltersComponentProps {
  displayFilters: SearchFilter[];
  selectedFilters: SelectedSearchFilter[];
  onUpdateSelectedFilters: (
    filter: string,
    value: boolean | string | number,
    type: string
  ) => void;
}

const SearchFiltersComponent: React.FC<SearchFiltersComponentProps> = ({
  displayFilters,
  selectedFilters,
  onUpdateSelectedFilters,
}) => {
  const [localSelectedFilters, setLocalSelectedFilters] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    const initialFilters: { [key: string]: string[] } = {};
    selectedFilters.forEach((filter) => {
      if (!initialFilters[filter.name]) {
        initialFilters[filter.name] = [];
      }
      if (Array.isArray(filter.value)) {
        filter.value.forEach((value) => {
          initialFilters[filter.name].push(value as string);
        });
        return;
      } else {
        initialFilters[filter.name].push(filter.value as string);
      }
    });
    setLocalSelectedFilters(initialFilters);
  }, [selectedFilters]);

  const handleFilterChange = (
    filterName: string,
    option: string,
    type: string
  ) => {
    setLocalSelectedFilters((prev) => {
      const newSelectedFilters = { ...prev };
      if (type === "checkbox") {
        if (!newSelectedFilters[filterName]) {
          newSelectedFilters[filterName] = [];
        }
        if (newSelectedFilters[filterName].includes(option)) {
          newSelectedFilters[filterName] = newSelectedFilters[
            filterName
          ].filter((item) => item !== option);
        } else {
          newSelectedFilters[filterName].push(option);
        }
      } else if (type === "typeahead") {
        newSelectedFilters[filterName] = [option];
      }
      else {
        newSelectedFilters[filterName] = [option];
      }
      return newSelectedFilters;
    });
    onUpdateSelectedFilters(filterName, option, type);
  };

  const handleRangeChange = (
    param: string,
    type: "min" | "max",
    value: string
  ) => {
    const filterKey = `${param}_${type}`;
    handleFilterChange(filterKey, value, "range");
  };

  const handleClearFilter = (filterName: string, filterType: string) => {
    if (filterType === "checkbox") {
      setLocalSelectedFilters((prev) => {
        const newSelectedFilters = { ...prev };
        delete newSelectedFilters[filterName];
        return newSelectedFilters;
      });
      onUpdateSelectedFilters(filterName, '', filterType);
    } else if (filterType === "range") {
      setLocalSelectedFilters((prev) => {
        const newSelectedFilters = { ...prev };
        delete newSelectedFilters[`${filterName}_min`];
        delete newSelectedFilters[`${filterName}_max`];
        return newSelectedFilters;
      });
      onUpdateSelectedFilters(`${filterName}_min`, '', filterType);
      onUpdateSelectedFilters(`${filterName}_max`, '', filterType);
    } else {
      setLocalSelectedFilters((prev) => {
        const newSelectedFilters = { ...prev };
        delete newSelectedFilters[filterName];
        return newSelectedFilters;
      });
      onUpdateSelectedFilters(filterName, '', filterType);
    }
  };

  return (
    <div className="flex flex-wrap gap-5 text-left bg-white text-gray-700 font-bold">
      {displayFilters.map((filter) => {
        if (filter.type === "checkbox") {
          // return early if no options
          if (!filter.options) return null;
          const currentFilter = localSelectedFilters[filter.param];
          let displayValue = "All";
          // if 1, then we can display the value, if more, then show "Multiple({count})", if 0, then show "All", if selected is same as the count, then show "All"
          if (currentFilter) {
            if (currentFilter.length === 1) {
              // find the display filter option label by the value
              const selectedOption = filter.options.find(
                (option) => option.value === currentFilter[0]
              );
              if (selectedOption) {
                displayValue = selectedOption.label;
              }
            } else if (
              currentFilter.length > 1 &&
              currentFilter.length < filter.options.length
            ) {
              displayValue = `Multiple(${currentFilter.length})`;
            } else if (currentFilter.length === 0) {
              displayValue = "None";
            }
          } else {
            displayValue = "None";
          }
          return (
            <div className="flex" key={filter.param}>
              <Popover title={filter.name} displayValue={displayValue}>
                <div className="p-4">
                {filter.options.map((option) => {
                  return (
                    <div key={option.value}>
                      <Checkbox
                        key={option.value}
                        label={option.label}
                        checked={
                          localSelectedFilters[filter.param]?.includes(
                            option.value
                          ) || false
                        }
                        onChange={(checked) =>
                          handleFilterChange(filter.param, option.value, filter.type)
                        }
                      />
                    </div>
                  );
                })}
                </div>
                <button
                  className="font-semibold text-base uppercase text-[#a9a8a9] bg-[#efeeec] px-4 py-2 rounded hover:bg-gray-100"
                  onClick={() => handleClearFilter(filter.param, filter.type)}
                >Clear
                </button>
              </Popover>
            </div>
          );
        }
        if (filter.type === "range") {
          let displayValue = "All";
          let allowDecimals = false;
          if (filter.param === "lot_size" || filter.param === "cap_rate") {
            allowDecimals = true;
          }
          const maxAllowed = filter.param === "cap_rate" ? 100 : Infinity;
          // if both values are set, then show the range, if only 1 is set, then show that value, if none are set, then show "All"
          const minValue = localSelectedFilters[`${filter.param}_min`]
            ? localSelectedFilters[`${filter.param}_min`][0]
            : "";
          const maxValue = localSelectedFilters[`${filter.param}_max`]
            ? localSelectedFilters[`${filter.param}_max`][0]
            : "";
          displayValue = formatRangeDisplayValues(
            minValue,
            maxValue,
            filter.param,
            allowDecimals
          );
          return (
            <div className="flex" key={filter.param}>
              <Popover title={filter.name} displayValue={displayValue}>
                <div className="p-4">
                  <Range
                    min={`${filter.param}_min`}
                    max={`${filter.param}_max`}
                    onChange={(type, value) =>
                      handleRangeChange(filter.param, type, value)
                    }
                    minValue={
                      (localSelectedFilters[`${filter.param}_min`] &&
                        localSelectedFilters[`${filter.param}_min`][0]) ||
                      ""
                    }
                    maxValue={
                      (localSelectedFilters[`${filter.param}_max`] &&
                        localSelectedFilters[`${filter.param}_max`][0]) ||
                      ""
                    }
                    maxAllowed={maxAllowed}
                    allowDecimals={allowDecimals}
                  />
                </div>
                <button
                  className="font-semibold text-base uppercase text-[#a9a8a9] bg-[#efeeec] px-4 py-2 rounded hover:bg-gray-100"
                  onClick={() => handleClearFilter(filter.param, filter.type)}
                >Clear
                </button>
              </Popover>
            </div>
          );
        }
        if (filter.type === "typeahead") {
          // return early if no options
          if (!filter.options) return null;
          let displayValue = "All";
          if (localSelectedFilters[filter.param]) {
            // get the selected option label by the value
            const filterOptions = displayFilters.find(
              (option) => option.param === filter.param
            )?.options;
            const selectedOption = filterOptions?.find(
              (option) => option.value === localSelectedFilters[filter.param][0]
            );
            if (selectedOption) {
              displayValue = selectedOption.label;
            }
          }
          return (
            <div className="flex" key={filter.param}>
              <Popover title={filter.name} displayValue={displayValue}>
                <div className="p-4">
                  <Typeahead
                    options={filter.options}
                    onSelect={(option) =>
                      handleFilterChange(filter.param, option, filter.type)
                    }
                    placeholder={filter.name}
                  />
                </div>
                <button
                  className="font-semibold text-base uppercase text-[#a9a8a9] bg-[#efeeec] px-4 py-2 rounded hover:bg-gray-100"
                  onClick={() => handleClearFilter(filter.param, filter.type)}
                >Clear
                </button>
              </Popover>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default withSearch(SearchWidget, process.env.REACT_APP_SEARCH_URL || "");
