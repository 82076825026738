// generate url slug formatting
export const generateSlug = (input: string) => {
  const nonWordRx = /[^\w]/gi;
  const specialCharRx = /[^A-Za-z0-9\-\s]/gi;
  const multipleHyphenRx = /-{2,4}/gi;
  // strip all non alphanumeric characters, leave spaces and hyphens
  const specialCharSweep = input.toLowerCase().replace(specialCharRx, "");
  // strip all non-words (according to regex) and add hyphens
  const nonWordSweep = specialCharSweep.replace(nonWordRx, "-");
  // replace all sequential hyphens with single
  const output = nonWordSweep.replace(multipleHyphenRx, "-");
  return output;
};

// formatting numerical display values
export const formatNumericalDisplayValue = (
  value: string,
  isDecimal: boolean = false
) => {
  const num = parseFloat(value);
  if (isNaN(num)) return false;

  let formatter = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: isDecimal ? 2 : 0,
  });
  let formattedNumber = formatter.format(num);
  return formattedNumber;
};

// formatting range display values
export const formatRangeDisplayValues = (
  min: string = "",
  max: string = "",
  type: string,
  isDecimal: boolean = false
) => {
  const minNum = formatNumericalDisplayValue(min, isDecimal);
  const maxNum = formatNumericalDisplayValue(max, isDecimal);

  // if no min and no max, return 'All'
  if (!minNum && !maxNum) return "All";

  let rangeValue = "";
  // if no min but has max, set rangeValue to 0 - max
  if (!minNum && maxNum) {
    rangeValue = `0 - ${maxNum}`;
  } else if (minNum && !maxNum) {
    rangeValue = `${minNum}+`;
  } else {
    rangeValue = `${minNum} - ${maxNum}`;
  }

  switch (type) {
    case "price":
      return `$${rangeValue}`;
    case "cap_rate":
      // insert % sign before the last + sign
      return `${rangeValue.replace(/\+$/, "%+")}`;
    case "lot_size":
      return `${rangeValue} AC`;
    case "size":
      return `${rangeValue} SF`;
    default:
      return rangeValue;
  }
};

// retrieve machineAccount token for es platform
export const retrieveEsToken = async (): Promise<string> => {
  // make request
  const response = await fetch(`${process.env.REACT_APP_ES_API_BASE}/auth/login`, {
    method: "POST",
    body: JSON.stringify({
      username: process.env.REACT_APP_ES_API_USERNAME,
      password: process.env.REACT_APP_ES_API_PASSWORD,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data.access_token;
};

// fetch Dynamic Typeahead options
export const fetchTypeaheadOptions = async () => {
  const options: any = {
    brokers: [],
    offices: [],
    tenants: [],
  };
  const esToken = await retrieveEsToken();
  try {
    const brokersResponse = await fetch(
      `${process.env.REACT_APP_ES_API_BASE}/teammembers/brokers`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${esToken}`,
        },
      }
    );
    const brokers = await brokersResponse.json();
    if (brokers.hits && brokers.hits.hits.length) {
      options.brokers = [];
      // add brokers to list
      brokers.hits.hits.map((broker: any) => {
        const name = `${broker._source.First_Name__c} ${broker._source.Last_Name__c}`;
        options.brokers?.push({ label: name, value: generateSlug(name) });
        return broker;
      });
      // sort brokers by label
      options.brokers.sort((a: { label: number }, b: { label: number }) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
    }
  } catch (error) {
    console.error(error);
  }

  // get offices
  try {
    const officesResponse = await fetch(
      `${process.env.REACT_APP_ES_API_BASE}/locations`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${esToken}`,
        },
      }
    );
    const offices = await officesResponse.json();
    if (offices.hits && offices.hits.hits.length) {
      options.offices = [];
      // add offices to list
      offices.hits.hits.map((office: any) => {
        const name = office._source.Name;
        options.offices?.push({ label: name, value: generateSlug(name) });
        return office;
      });
    }
  } catch (error) {
    console.error(error);
  }

  // get tenants
  try {
    const tenantsResponse = await fetch(
      `${process.env.REACT_APP_ES_API_BASE}/properties/tenants/cache`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${esToken}`,
        },
      }
    );
    const tenants = await tenantsResponse.json();
    if (tenants && tenants.length) {
      options.tenants = [];
      // add tenants to list
      tenants.map((tenant: any) => {
        options.tenants?.push({ label: tenant.label, value: tenant.lookup });
        return tenant;
      });
    }
  } catch (error) {
    console.error(error);
  }
  return options;
};
