import React, { useState, useRef, useEffect } from "react";

function Popover({
  title,
  displayValue = "All",
  children,
}: {
  title: string;
  displayValue?: string;
  children: React.ReactNode;
}) {
  const [popoversOpen, setPopoversOpen] = useState(false);

  const trigger = useRef<any>(null);
  const popovers = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!popovers.current) return;
      if (
        !popoversOpen ||
        popovers.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setPopoversOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!popoversOpen) return;
      // if not esc, return
      if (keyCode !== 27) return;
      setPopoversOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="w-full search-widget-popover flex">
      <div className="text-center flex">
        <div className="relative flex">
          <div
            ref={trigger}
            onClick={() => setPopoversOpen(!popoversOpen)}
            onKeyDown={(e) => {
              // if space, enter, or down arrow key is pressed
              if (e.key === " " || e.key === "Enter" || e.key === "ArrowDown") {
                e.preventDefault();
                setPopoversOpen(true);
              }
            }}
            className={`flex flex-col items-start w-36 border-2 font-medium py-2 text-[16px] text-left rounded-none search-widget-popover-trigger ${!["All", "None"].includes(displayValue) ? "border-transparent bg-[#DEE7EB] " : "border-gray-200 bg-transparent" } cursor-pointer`}
            tabIndex={0}
          >
            <div className="uppercase font-bold text-[#35647e] leading-[18px] mb-0.5">{title}</div>
            <div className="font-bold">{displayValue}</div>
          </div>
          <div
            ref={popovers}
            onFocus={() => setPopoversOpen(true)}
            className={`absolute left-0 top-full z-20 mt-3 w-max max-w-[300px] rounded-none bg-white drop-shadow border-[1px] border-gray-200 dark:bg-meta-4 ${
              popoversOpen === true ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popover;
