import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import SearchWidget from './SearchWidget';
import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('SearchWidget') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SearchWidget />
    </BrowserRouter>
  </React.StrictMode>
);
