import React from 'react';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === ' ') {
      e.preventDefault();
      onChange(!checked);
    }
  };

  return (
    <label className="relative flex items-center px-5 h-10 cursor-pointer mb-0">
      <input
        tabIndex={-1}
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        className="absolute opacity-0 h-0 w-0"
      />
      <span
      tabIndex={0}
      onKeyDown={handleKeyDown}
        className={`absolute w-5 h-5 left-0 top-1/2 transform -translate-y-1/2 border-2 rounded flex items-center justify-center transition-all duration-150 mr-2 ${
          checked ? 'bg-[#dae3e8] border-[#dae3e8]' : 'bg-[#efeeec] border-[#dbd9d6]'
        }`}
      >
        {checked && (
          <svg
            className="w-4 h-4 text-[#35647e]"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        )}
      </span>
      <span className="ml-4 text-base text-left leading-[18px]">{label}</span>
    </label>
  );
};

export default Checkbox;

// height: 44px;
//     display: flex
// ;
//     -webkit-box-align: center;
//     align-items: center;
//     padding-left: 20px;
//     padding-right: 20px;