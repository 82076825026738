import React, { useState, useEffect, useRef } from 'react';

interface TypeaheadOption {
  label: string;
  value: string;
}
interface TypeaheadProps {
  options: TypeaheadOption[];
  onSelect: (option: string) => void;
  placeholder?: string;
}

const Typeahead: React.FC<TypeaheadProps> = ({ options, onSelect, placeholder }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<TypeaheadOption[]>(options);
  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const listRef = useRef<HTMLUListElement>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setFilteredOptions(options.filter(option => option.label.toLowerCase().includes(value.toLowerCase())));
    setShowDropdown(true);
    setHighlightedIndex(-1);
  };

  const getInputValue = (selectedOption: string) => {
    return options.find(option => option.value === selectedOption)?.label || '';
  }

  const handleOptionClick = (option: string) => {
    const inputValue = getInputValue(option);
    setInputValue(inputValue);
    setShowDropdown(false);
    onSelect(option);
  };

  const handleClear = () => {
    setInputValue('');
    setFilteredOptions(options);
    setShowDropdown(false);
    setHighlightedIndex(-1);
    onSelect('');
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setShowDropdown(false);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % filteredOptions.length;
        itemRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
        return newIndex;
      });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + filteredOptions.length) % filteredOptions.length;
        itemRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
        return newIndex;
      });
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault();
      handleOptionClick(filteredOptions[highlightedIndex].value);
    }
  };

  useEffect(() => {
    // set filtered options to all options when the component mounts
    setFilteredOptions(options);
  }, [options]);

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        placeholder={placeholder}
        className="p-3.5 h-11 flex items-center border-2 border-gray-300 rounded-none text-sm w-full font-bold font-sans text-gray-700"
      />
      {inputValue && (
        <button
          onClick={handleClear}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 text-xl w-[12px] min-w-[12px] hover:bg-transparent"
        >
          &times;
        </button>
      )}
      {showDropdown && (
        <ul ref={listRef} className="absolute z-100 w-full bg-[#f9f9f9] border border-gray-300 rounded mt-0.5 max-h-[300px] overflow-auto shadow-md text-base text-left list-none p-0 m-0">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              ref={(el) => (itemRefs.current[index] = el)}
              onMouseDown={() => handleOptionClick(option.value)}
              onMouseEnter={() => setHighlightedIndex(index)}
              className={`p-2 cursor-pointer hover:bg-gray-200 ml-0 ${highlightedIndex === index ? 'bg-gray-200' : ''}`}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Typeahead;